import React, { useEffect, useRef, useState } from 'react';
import { Button, keyframes, usePrefersReducedMotion } from '@chakra-ui/react';

import download from '../utils/download';

const expand = keyframes`
  from { transform:  scale(1); }
  10% { transform:  scale(0.85); }
  60% { transform: scale(1.05); }
  80% { transform: scale(0.98); }
  to { transform: scale(1); }
`;

const AnimatedButton = ({ children }) => {
  const ref = useRef(null);
  const [shouldAnimateIcon, setShouldAnimateIcon] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    let intersectionObserver;
    if (ref.current) {
      intersectionObserver = new IntersectionObserver(function (entries) {
        if (entries?.[0]?.isIntersecting) {
          setShouldAnimateIcon(true);
          intersectionObserver.disconnect();
        }
      });

      intersectionObserver.observe(ref.current, { attributes: true });
    }

    return () => {
      if (intersectionObserver) {
        intersectionObserver.disconnect();
      }
    };
  }, []);

  const animation =
    prefersReducedMotion || !shouldAnimateIcon
      ? undefined
      : `${expand} 1 1.3s ease-in-out`;

  return (
    <Button
      ref={ref}
      width={{ base: '100%', md: 'auto' }}
      onClick={download}
      fontSize="20px"
      animation={animation}
    >
      {children}
    </Button>
  );
};

export default AnimatedButton;
