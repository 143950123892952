import benzinga from '../../../../static/images/img-media-benzinga.jpeg';
import coindesk from '../../../../static/images/img-media-coindesk.jpg';
import cointelegraph from '../../../../static/images/img-media-cointelegraph.jpeg';
import globalVc from '../../../../static/images/img-media-global-vc.jpeg';
import yahoo from '../../../../static/images/img-media-yahoo.jpg';
import yahooNFT from '../../../../static/images/img-media-yahoo-nft.jpeg';

export const NewsInEnglish = [
  {
    url: 'https://www.coindesk.com/markets/2021/06/18/want-to-own-a-piece-of-cnns-news-history-theres-an-nft-for-that/#',
    image: coindesk,
    imageAlt:
      'CoinDesk - Want to Own a Piece of CNN’s News History? There’s an NFT for That',
    title: 'Want to Own a Piece of CNN’s News History? There’s an NFT for That',
  },
  {
    url: 'https://500.co/theglobalvc/blocto-is-making-crypto-and-blockchain-apps-accessible',
    image: globalVc,
    imageAlt:
      '500 GLOBAL - Blocto Is Making Crypto and Blockchain Apps Accessible',
    title: 'Blocto Is Making Crypto and Blockchain Apps Accessible',
  },
  {
    url: 'https://finance.yahoo.com/news/mark-cuban-invests-crypto-wallet-145111873.html#',
    image: yahoo,
    imageAlt: 'Yahoo - Mark Cuban invests in crypto wallet Blocto',
    title: 'Mark Cuban invests in crypto wallet Blocto',
  },
  {
    url: 'https://cointelegraph.com/press-releases/crypto-wallet-blocto-brings-the-first-dex-bloctoswap-on-flow#',
    image: cointelegraph,
    imageAlt:
      'Cointelegraph - Crypto wallet Blocto brings the first DEX BloctoSwap on Flow',
    title: 'Crypto wallet Blocto brings the first DEX BloctoSwap on Flow',
  },
  {
    url: 'https://www.investing.com/news/cryptocurrency-news/yahoo-and-crypto-wallet-blocto-join-hands-to-launch-nft-store-2786350',
    image: yahooNFT,
    imageAlt:
      'Investing.com - Yahoo and Crypto Wallet Blocto Join Hands to Launch NFT Store',
    title: 'Yahoo and Crypto Wallet Blocto Join Hands to Launch NFT Store',
  },
  {
    url: 'https://www.benzinga.com/markets/cryptocurrency/21/09/23053317/a-step-towards-blockchain-cross-industry-integration-interview-with-ceo-of-blocto#',
    image: benzinga,
    imageAlt:
      'Benzinga - A Step Towards Blockchain Cross-Industry Integration: Interview With CEO Of Blocto',
    title:
      'A Step Towards Blockchain Cross-Industry Integration: Interview With CEO Of Blocto',
  },
];
