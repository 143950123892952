import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';
import {
  Box,
  Heading,
  Image,
  keyframes,
  Text,
  usePrefersReducedMotion,
  useStyleConfig,
} from '@chakra-ui/react';
import useAOS from '../utils/useAOS';

const expand = keyframes`
  from { opacity: 0; transform: scale(0); }
  to { opacity: 1; transform: scale(1); }
`;

const FeatureCard = ({ icon, titleTranslationKey, contentTranslationKey }) => {
  const cardStyles = useStyleConfig('Card');
  const ref = useRef(null);
  const [shouldAnimateIcon, setShouldAnimateIcon] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();
  const aosAnimation = useAOS({
    'data-aos': 'fade-up',
    'data-aos-duration': '600',
    'data-aos-offset': '400',
  });

  useEffect(() => {
    let mutationObserver;
    if (ref.current) {
      mutationObserver = new MutationObserver(function (entries) {
        for (const entry of entries) {
          if (entry.target.className.includes('aos-animate')) {
            setShouldAnimateIcon(true);
            mutationObserver.disconnect();
          }
        }
      });

      mutationObserver.observe(ref.current, { attributes: true });
    }

    return () => {
      if (mutationObserver) {
        mutationObserver.disconnect();
      }
    };
  }, []);

  const animation =
    prefersReducedMotion || !shouldAnimateIcon
      ? undefined
      : `${expand} 1 .3s linear forwards`;

  return (
    <Box
      ref={ref}
      __css={cardStyles}
      width={{ base: 'calc(100% + 8px)', md: '480px' }}
      minWidth="0"
      minHeight="594px"
      padding={{ base: '40px 12px 80px', md: '80px 20px' }}
      textAlign="center"
      marginLeft={{ base: '-4px', md: '30px' }}
      marginBottom={{ base: '30px', md: '0' }}
      position="relative"
      _first={{ marginLeft: { base: '-4px', md: '0' } }}
      _last={{ marginBottom: '0' }}
      _after={{
        content: '""',
        display: 'inline-block',
        width: '60px',
        height: '10px',
        bg: 'porttoGrayBlue',
        opacity: 0.1,
        borderRadius: '20px',
        position: 'absolute',
        bottom: '30px',
        left: '0',
        right: '0',
        margin: '0 auto',
      }}
      {...aosAnimation}
    >
      <Box
        display="inline-flex"
        justifyContent="center"
        alignItems="center"
        width={{ base: '100px', md: '100px' }}
        height={{ base: '100px', md: '100px' }}
        padding={{ base: '10px', md: '20px' }}
        borderRadius="50%"
        bg="porttoGrayBlue"
      >
        <Image
          src={icon}
          display="inline-block"
          opacity={prefersReducedMotion || !shouldAnimateIcon ? '1' : '0'}
          transform={
            prefersReducedMotion || !shouldAnimateIcon ? undefined : 'scale(0)'
          }
          animation={animation}
        />
      </Box>
      <Heading
        as="h2"
        variant="h2"
        color="porttoBlack"
        minHeight="64px"
        margin="30px 0"
      >
        <FormattedMessage id={titleTranslationKey} />
      </Heading>
      <Text fontSize="20px" lineHeight="30px" color="porttoBlack">
        <FormattedMessage id={contentTranslationKey} />
      </Text>
    </Box>
  );
};

FeatureCard.propTypes = {
  icon: PropTypes.string,
  titleTranslationKey: PropTypes.string.isRequired,
  contentTranslationKey: PropTypes.string.isRequired,
};

FeatureCard.defaultProps = {
  icon: '',
};

export default FeatureCard;
