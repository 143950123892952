import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Image,
  keyframes,
  usePrefersReducedMotion,
} from '@chakra-ui/react';

import bloctoLogo from '../../static/images/icon-blocto-logo.svg';

const expand = keyframes`
  from { opacity: 0; transform: scale(0); }
  to { opacity: 1; transform: scale(1); }
`;

const AnimatedLogo = () => {
  const ref = useRef(null);
  const [shouldAnimateIcon, setShouldAnimateIcon] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    let intersectionObserver;
    if (ref.current) {
      intersectionObserver = new IntersectionObserver(function (entries) {
        if (entries?.[0]?.isIntersecting) {
          setShouldAnimateIcon(true);
          intersectionObserver.disconnect();
        }
      });

      intersectionObserver.observe(ref.current, { attributes: true });
    }

    return () => {
      if (intersectionObserver) {
        intersectionObserver.disconnect();
      }
    };
  }, []);

  const animation =
    prefersReducedMotion || !shouldAnimateIcon
      ? undefined
      : `${expand} 1 .3s linear forwards`;

  return (
    <Box
      display="inline-block"
      width={{ base: '140px', md: '180px' }}
      height={{ base: '140px', md: '180px' }}
      padding="30px"
      borderRadius="50%"
      boxShadow="0px 0px 20px rgba(127, 127, 127, 0.05)"
      animation={animation}
    >
      <Image
        ref={ref}
        src={bloctoLogo}
        alt="Blocto logo"
        display="inline-block"
        width="100%"
      />
    </Box>
  );
};

export default AnimatedLogo;
