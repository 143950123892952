import React, { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { FormattedMessage } from 'gatsby-plugin-intl';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  keyframes,
  useBreakpointValue,
  usePrefersReducedMotion,
} from '@chakra-ui/react';

import download from '../utils/download';

import heroBall from '../../static/images/bg-hero-ball.png';
import heroBottom from '../../static/images/bg-hero-bottom.png';
import heroShadow from '../../static/images/bg-hero-image-shadow.png';

const fadeUp = keyframes`
  from { transform: translateY(250px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const float = keyframes`
  0% { transform: translateY(15px); }
  40% { transform: translateY(-20px); }
  100% { transform: translateY(15px); }
`;

const biggerMotionfloat = keyframes`
  0% { transform: translateY(30px); }
  40% { transform: translateY(-20px); }
  100% { transform: translateY(30px); }
`;

const shrink = keyframes`
  0% { transform: translateX(-50%) scale(1); }
  40% { transform: translateX(-50%) scale(0.93) }
  100% { transform: translateX(-50%) scale(1); }
`;

const Hero = () => {
  const containerRef = useRef(null);
  const [opacity, setOpacity] = useState(1);
  const isMinWidthLg = useBreakpointValue({ base: false, md: true });
  const prefersReducedMotion = usePrefersReducedMotion();

  const handleScroll = useCallback(() => {
    if (containerRef.current && isMinWidthLg === false) {
      const updatedOpacity =
        1 -
        document.documentElement.scrollTop / containerRef.current.offsetHeight;
      setOpacity(updatedOpacity < 0 ? 0 : updatedOpacity);
    }
  }, [isMinWidthLg]);

  const debouncedHandleScroll = useCallback(debounce(handleScroll), [
    handleScroll,
  ]);

  useEffect(() => {
    if (
      document.documentElement.scrollTop >= containerRef.current.offsetHeight
    ) {
      setOpacity(0);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', debouncedHandleScroll);
    return () => document.removeEventListener('scroll', debouncedHandleScroll);
  }, [debouncedHandleScroll]);

  const fadeUpAnimation =
    prefersReducedMotion || isMinWidthLg === false
      ? undefined
      : `${fadeUp} 1 .6s ease-in-out forwards`;

  const floatAnimation =
    prefersReducedMotion || opacity === 0
      ? undefined
      : `${float} infinite 3.2s linear`;

  const biggerMotionfloatAnimation =
    prefersReducedMotion || opacity === 0
      ? undefined
      : `${biggerMotionfloat} infinite 3.2s linear`;

  const shrinkAnimation =
    prefersReducedMotion || opacity === 0
      ? undefined
      : `${shrink} infinite 3.2s linear`;

  return (
    <Flex
      ref={containerRef}
      alignItems="center"
      height={{ base: 'auto', md: '664px' }}
      paddingTop={{ base: '91px', md: '60px' }}
      opacity={isMinWidthLg !== false && !prefersReducedMotion ? '0' : '1'}
      transform={
        isMinWidthLg !== false && !prefersReducedMotion
          ? 'translateY(250px)'
          : undefined
      }
      animation={fadeUpAnimation}
    >
      <Box flex="1">
        <Box
          bg="rgba(255, 255, 255, 0.7)"
          borderRadius="30px"
          py={{ base: '20px', md: '0' }}
          marginBottom={{ base: '30px', md: '70px' }}
        >
          <Heading
            as="h1"
            variant="h1"
            textAlign={{ base: 'center', md: 'left' }}
          >
            <FormattedMessage id="blocto.home.hero.title" />
          </Heading>
          <Heading
            as="h4"
            variant="h4"
            fontWeight="400"
            lineHeight="30px"
            margin="30px 0 0"
            textAlign={{ base: 'center', md: 'left' }}
          >
            <FormattedMessage id="blocto.home.hero.content" />
          </Heading>
        </Box>

        <Button
          onClick={download}
          fontSize="20px"
          width={{ base: '100%', md: 'auto' }}
        >
          <FormattedMessage id="blocto.common.downloadNow" />
        </Button>
      </Box>

      <Box
        width={{ base: '437px', md: '46%' }}
        maxWidth="552px"
        marginLeft={{ base: '0', md: 'min(110px, 9.2%)' }}
        position={{ base: 'fixed', md: 'relative' }}
        top={{ base: '51px', md: 'unset' }}
        right={{ base: '-128px', md: 'unset' }}
        zIndex="-1"
        transition=".2s opacity"
        style={{ opacity }}
      >
        <Box position="relative">
          <Image
            src={heroBall}
            width="100%"
            animation={biggerMotionfloatAnimation}
          />
          <Image
            src={heroBottom}
            width="100%"
            position="absolute"
            top="0"
            zIndex="-1"
            animation={floatAnimation}
          />
        </Box>
        <Image
          src={heroShadow}
          width="96%"
          position="absolute"
          top="49px"
          left="50%"
          zIndex="-2"
          animation={shrinkAnimation}
        />
      </Box>
    </Flex>
  );
};

export default Hero;
