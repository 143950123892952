import React, { useCallback, useState, useEffect } from 'react'
import { Flex, Box, Text, Button, Image, Center, chakra, Fade } from "@chakra-ui/react";
import { Player } from "@lottiefiles/react-lottie-player";
import { FormattedMessage } from 'gatsby-plugin-intl';
import closeIcon from '../../static/images/icon-close.svg';
import bloctoLogo from '../../static/images/icon-blocto-logo-new.svg';
import loadingCircleRight from "../../static/lottie/logo-loading-right.json";

const PORTTO_REDIRECT_HINT_ACKED = "PORTTO_REDIRECT_HINT_ACKED"
const ChakraPlayer = chakra(Player)

const NewSiteHint = () => {
  const [showHint, setShowHint] = useState(false)

  const close = useCallback(() => {
    setShowHint(false);
    localStorage.setItem(PORTTO_REDIRECT_HINT_ACKED, false)
  }, [])

  const redirect = useCallback(() => {
    window.location.href = "https://blocto.io"
  }, [])

  useEffect(() => {
    const acked = localStorage.getItem(PORTTO_REDIRECT_HINT_ACKED)
    setShowHint(acked !== "false")
  }, [])

  return showHint && (
    <Flex
      width="100vw"
      height="100vh"
      position="fixed"
      left="0"
      top="0"
      zIndex={10000}
      bgColor="rgba(0,0,0,0.2)"
      onClick={close}
    >
      <Box
        margin="auto"
        marginBottom={{ base: 0, md: "auto" }}
      >
        <Fade in={true}>
          <Box
            bg="white"
            borderRadius={{ base: "12px 12px 0 0", md: "12px" }}
            px={5}
            py={4}

            maxW={{ base: "100%", md: 375 }}
            textAlign="center"
            onClick={e => e.stopPropagation()}
          >
            <Flex justify="flex-end">
              <Image src={closeIcon} role="button" onClick={close} />
            </Flex>
            <Center mb={6}>
              <Flex
                justifyContent="center"
                alignItems="center"
                width="60px"
                height="60px"
                position="relative"
              >
                <Image src={bloctoLogo} width={8} height={8} />
                <ChakraPlayer
                  width="100%"
                  autoplay
                  loop
                  src={loadingCircleRight}
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                />
              </Flex>
            </Center>
            <Text fontSize="1.25rem" mb={2} fontWeight="bold">
              <FormattedMessage id="blocto.common.newSiteHint.title" />
            </Text>
            <Text textAlign="center" lineHeight={1.5} mb="50px" fontSize="13px">
              <FormattedMessage id="blocto.common.newSiteHint.body" />
            </Text>
            <Button borderRadius="12px" width="100%" mb={4} onClick={redirect}>
              <FormattedMessage id="blocto.common.newSiteHint.button" />
            </Button>
            <Box role="button" color="porttoGrayBlue" mb={4} onClick={close}>
              <FormattedMessage id="blocto.common.newSiteHint.footer" />
            </Box>
          </Box>
        </Fade>
      </Box>
    </Flex>
  )
}

export default NewSiteHint