/* eslint-disable max-len */
import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useStyleConfig,
} from '@chakra-ui/react';
import avatar1 from '../../static/images/img-avatar-1.png';
import avatar2 from '../../static/images/img-avatar-2.png';
import avatar3 from '../../static/images/img-avatar-3.png';
import avatar4 from '../../static/images/img-avatar-4.png';
import avatar5 from '../../static/images/img-avatar-5.png';
import avatar6 from '../../static/images/img-avatar-6.png';

const Feedback = [
  {
    avatar: avatar1,
    name: 'Crypto BΞACH',
    id: '@CryptoB18130730',
    comment:
      'I love the ease of using your non-custodian wallet, really best user experience on FLOW. would like to be able to swap from flow and blt to btc, eth in the wallet itself. also incetivising a blt-flow pool for bloctoswap is needed',
    timestamp: '10:17 · PM Nov 17,2021',
  },
  {
    avatar: avatar2,
    name: 'coach gg',
    id: '@coachgg2',
    comment:
      "one unique feature about Blocto is u just use email to login! u don't even have to remember password or even seed phrase! this ease lots of burden especially newbies of crypto! the most user-friendly defi wallet!",
    timestamp: '4:58 AM · Nov 18,2021',
  },
  {
    avatar: avatar3,
    name: 'Kelvincoin',
    id: '@kelkelcoin',
    comment:
      'I found this hidden gem(not hidden anymore)when I was doing research on FLOW. Blocto is not just a wallet, go read its white paper and you will know there is much more to expect from Blocto',
    timestamp: '10:17 · PM Nov 17,2021',
  },
  {
    avatar: avatar4,
    name: 'ThePartyGoobz',
    id: '@TheGooberZNFT',
    comment:
      'ease of use, seriously, this wallet and low gas prices is why we chose Flow to build on. The fact you can swap token and see collections in one place is next level.',
    timestamp: '11:52 PM · Nov 17,2021',
  },
  {
    avatar: avatar5,
    name: 'HalfStepAhead',
    id: '@halfstepahead',
    comment:
      'If we’re gonna live in the metaverse @theMatrixWorld and got to choose only 3 identities, I’m proud to be a Bloctopian',
    timestamp: '10:17 · PM Nov 17,2021',
  },
  {
    avatar: avatar6,
    name: 'frank',
    id: '@frankHoDL',
    comment:
      'Blocto has a very considerate and community-oriented mindset! They care about us!',
    timestamp: '11:07 · PM Nov 17,2021',
  },
];

const UserFeedback = () => {
  const cardStyles = useStyleConfig('Card');
  return (
    <Flex
      flexWrap={{ base: 'unset', md: 'wrap' }}
      sx={{
        '@media screen and (max-width: 1123px)': {
          flexWrap: 'unset',
          overflow: 'auto',
          padding: '20px', // For not cutting the box shadow
          margin: '-20px', // For not cutting the box shadow
        },
      }}
    >
      {Feedback.map((user) => (
        <Box
          key={user.id}
          __css={cardStyles}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flex={{ base: '1 0 300px', md: '1 0 32%' }}
          height={{ base: '366px', md: '386px' }}
          padding={{ base: '30px 18px 40px', md: '40px 30px' }}
          margin={{ base: '10px', md: '1%' }}
          marginTop={{ base: '0' }}
          marginBottom={{ base: '0' }}
          _first={{ marginLeft: '0' }}
          _last={{ marginRight: '0' }}
          sx={{
            '@media screen and (min-width: 767px) and (max-width: 1123px)': {
              flex: '1 0 45%',
              margin: '0 10px',
            },
            '@media screen and (min-width: 1124px)': {
              ':nth-of-type(3n)': {
                marginRight: { base: '10px', md: '0' },
              },
              ':nth-of-type(3n+1)': {
                marginLeft: { base: '10px', md: '0' },
              },
              ':nth-of-type(-n+3)': {
                marginTop: '0',
              },
              ':nth-of-type(n+4)': {
                marginBottom: '0',
              },
            },
          }}
        >
          <Box>
            <Flex
              alignItems="center"
              marginBottom={{ base: '20px', md: '30px' }}
            >
              <Image width={{ base: '50px', md: '60px' }} src={user.avatar} />
              <Box marginLeft="20px">
                <Heading
                  variant="h4"
                  fontWeight="500"
                  color="porttoBlack"
                  marginBottom={{ base: '2px', md: '10px' }}
                >
                  {user.name}
                </Heading>
                <Text color="porttoGray01">{user.id}</Text>
              </Box>
            </Flex>

            <Text fontSize="16px" lineHeight="26px">
              {user.comment}
            </Text>
          </Box>

          <Text fontSize="16px" lineHeight="20px" color="porttoGray01">
            {user.timestamp}
          </Text>
        </Box>
      ))}
    </Flex>
  );
};

export default UserFeedback;
