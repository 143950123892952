import { useMemo } from 'react';
import isBrowser from './isBrowser';

const useAOS = (aos) => {
  const isInBrowser = isBrowser();
  const shouldShowAnimation = useMemo(
    () => isInBrowser && window.location.hash !== '#news',
    [isInBrowser]
  );
  return useMemo(() => (shouldShowAnimation ? aos : {}), [shouldShowAnimation]);
};

export default useAOS;
