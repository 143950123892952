import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Image,
  Link,
  Text,
  keyframes,
  usePrefersReducedMotion,
} from '@chakra-ui/react';

const AnimationDuration = {
  fast: 70,
  medium: 200,
  slow: 300,
};

const Carousel = ({ partners, direction, speed }) => {
  const containerRef = useRef(null);
  const groupRef = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();
  const [timeforShowingFirstClone, setTimeforShowingFirstClone] = useState(0);
  const [timeforShowingSecondClone, setTimeforShowingSecondClone] = useState(0);
  const [movingTimeRatio, setMovingTimeRatio] = useState(0);

  const duration = AnimationDuration[speed];

  useEffect(() => {
    let resizeObserver;
    if (containerRef.current && groupRef.current) {
      resizeObserver = new ResizeObserver((entries) => {
        const observedWidth = entries?.[0]?.contentRect?.width;
        if (containerRef.current && groupRef.current) {
          const movement = observedWidth + 1440;
          const updatedMovingTimeRatio =
            movement / (movement + (observedWidth - 1440));
          setMovingTimeRatio(updatedMovingTimeRatio);
          setTimeforShowingFirstClone(
            (observedWidth - containerRef.current.offsetWidth - 120) /
              (movement / (duration * updatedMovingTimeRatio))
          );
          setTimeforShowingSecondClone(
            observedWidth / (movement / (duration * updatedMovingTimeRatio))
          );
        }
      });

      resizeObserver.observe(groupRef.current);
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  const scrollLeft = keyframes`
    0% { transform: translateX(0px); opacity: 1; }
    ${
      movingTimeRatio * 100
    }% { transform: translateX(calc(-100% - 1440px)); opacity: 1; }
    ${movingTimeRatio * 100 + 0.1}% { opacity: 0; }
    100% { transform: translateX(0px); opacity: 0; }
  `;

  const scrollRight = keyframes`
    0% { transform: translateX(0px); opacity: 1; }
    ${
      movingTimeRatio * 100
    }% { transform: translateX(calc(100% + 1440px)); opacity: 1; }
    ${movingTimeRatio * 100 + 0.1}% { opacity: 0; }
    100% { transform: translateX(0px); opacity: 0; }
  `;

  const animationKeyframes = direction === 'left' ? scrollLeft : scrollRight;
  return (
    <Flex
      ref={containerRef}
      justifyContent={direction === 'left' ? 'flex-start' : 'flex-end'}
      my="15px"
      position="relative"
      _first={{ marginTop: '0' }}
      _last={{ marginBottom: '0' }}
    >
      <Flex
        ref={groupRef}
        flex="0 0 auto"
        willChange="transform"
        animation={
          prefersReducedMotion
            ? undefined
            : `${animationKeyframes} 1 ${duration}s linear forwards`
        }
      >
        <Itmes items={partners} />
      </Flex>

      {React.createElement(
        Flex,
        {
          flex: '0 0 auto',
          width: 'max-content',
          position: 'absolute',
          left: direction === 'left' ? 'calc(100% + 120px)' : undefined,
          right: direction === 'right' ? 'calc(100% + 120px)' : undefined,
          willChange: 'transform',
          animation: prefersReducedMotion
            ? undefined
            : `${animationKeyframes} infinite ${duration}s linear ${timeforShowingFirstClone}s`,
        },
        <Itmes items={partners} />
      )}

      {React.createElement(
        Flex,
        {
          flex: '0 0 auto',
          width: 'max-content',
          position: 'absolute',
          left: direction === 'left' ? 'calc(100% + 120px)' : undefined,
          right: direction === 'right' ? 'calc(100% + 120px)' : undefined,
          willChange: 'transform',
          animation: prefersReducedMotion
            ? undefined
            : `${animationKeyframes} infinite ${duration}s linear ${
                timeforShowingFirstClone + timeforShowingSecondClone
              }s`,
        },
        <Itmes items={partners} />
      )}
    </Flex>
  );
};

Carousel.propTypes = {
  partners: PropTypes.arrayOf(
    PropTypes.shape({ icon: PropTypes.string, name: PropTypes.string })
  ),
  direction: PropTypes.oneOf(['left', 'right']),
  speed: PropTypes.oneOf(['fast', 'medium', 'slow']),
};

Carousel.defaultProps = {
  partners: [],
  direction: 'left',
  speed: 'medium',
};

const Itmes = ({ items }) => {
  return items.map((item) => (
    <Link key={item.name} href={item.href} isExternal>
      <Flex
        alignItems="center"
        height="78px"
        px="20px"
        mx="10px"
        borderRadius="20px"
        boxShadow="0px 0px 20px rgba(127, 127, 127, 0.05)"
      >
        <Image
          src={item.icon}
          width="46px"
          height="46px"
          borderRadius="50%"
          marginRight="12px"
        />
        <Text fontSize="24px" lineHeight="28px" color="porttoBlack">
          {item.name}
        </Text>
      </Flex>
    </Link>
  ));
};

Itmes.propTypes = {
  partners: PropTypes.arrayOf(
    PropTypes.shape({ icon: PropTypes.string, name: PropTypes.string })
  ),
};

Itmes.defaultProps = {
  partners: [],
};

export default Carousel;
