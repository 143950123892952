import benzinga from '../../../../static/images/img-media-benzinga.jpeg';
import businessToday from '../../../../static/images/img-media-business-today.jpg';
import globalVc from '../../../../static/images/img-media-global-vc.jpeg';
import meet from '../../../../static/images/img-media-meet.jpeg';
import yahoo from '../../../../static/images/img-media-yahoo.jpg';
import yahooNFT from '../../../../static/images/img-media-yahoo-nft.jpeg';

export const NewsInChinese = [
  {
    url: 'https://meet.bnext.com.tw/articles/view/48051#',
    image: meet,
    imageAlt:
      'Meet - The Dallas Mavericks owner, Mark Cuban, has invested in Portto.',
    title:
      'NBA球隊老闆成為戰略投資人！加密貨幣錢包服務新創portto如何開啟NFT交易新篇章？',
  },
  {
    url: 'https://www.businesstoday.com.tw/article/category/183015/post/202112060038/%E5%B0%88%E8%A8%AA%20Blocto%20%E6%9D%8E%E7%8E%84%E3%80%81%E9%A1%8F%E7%B6%AD%E4%BD%90%EF%BC%8C%E9%97%9C%E6%96%BC%E5%85%B1%E5%90%8C%E5%89%B5%E8%BE%A6%E4%BA%BA%E7%9A%84%E6%A0%BC%E5%B1%80%E3%80%81%E5%88%86%E5%B7%A5%E3%80%81%E6%BA%9D%E9%80%9A%E8%88%87%E6%88%90%E5%B0%B1%E5%BD%BC%E6%AD%A4%E9%80%99%E4%B8%80%E5%A0%82%E8%AA%B2',
    image: businessToday,
    imageAlt:
      'Business Today - Experience sharing from Hsuan & Edwin from portto',
    title:
      '專訪 Blocto 李玄、顏維佐，關於共同創辦人的格局、分工、溝通與成就彼此這一堂課',
  },
  {
    url: 'https://finance.yahoo.com/news/mark-cuban-invests-crypto-wallet-145111873.html#',
    image: yahoo,
    imageAlt: 'Yahoo - Mark Cuban invests in crypto wallet Blocto',
    title:
      'NBA球隊達拉斯獨行俠老闆 Mark Cuban 投資虛擬貨幣錢包 Blocto (Mark Cuban invests in crypto wallet Blocto)',
  },
  {
    url: 'https://www.benzinga.com/markets/cryptocurrency/21/09/23053317/a-step-towards-blockchain-cross-industry-integration-interview-with-ceo-of-blocto#',
    image: benzinga,
    imageAlt:
      'Benzinga - A Step Towards Blockchain Cross-Industry Integration: Interview With CEO Of Blocto',
    title:
      '邁向區塊鏈跨產業整合的一步：Blocto CEO 專訪 (A Step Towards Blockchain Cross-Industry Integration: Interview With CEO Of Blocto)',
  },
  {
    url: 'https://500.co/theglobalvc/blocto-is-making-crypto-and-blockchain-apps-accessible',
    image: globalVc,
    imageAlt:
      '500 GLOBAL - Blocto Is Making Crypto and Blockchain Apps Accessible',
    title:
      'Blocto 使虛擬貨幣和區塊鏈世界更平易近人 (Blocto Is Making Crypto and Blockchain Apps Accessible)',
  },
  {
    url: 'https://tw.news.yahoo.com/yahoo%E5%A5%87%E6%91%A9%E6%94%9C%E6%89%8Bblocto%E6%8F%92%E6%97%97nft%E5%B8%82%E5%A0%B4-%E4%B8%89%E6%9C%88%E5%BA%95%E6%AD%A3%E5%BC%8F%E6%8E%A8%E5%87%BA-nft%E5%95%86%E5%BA%97-100000312.html',
    image: yahooNFT,
    imageAlt:
      'Yahoo - Yahoo奇摩攜手Blocto插旗NFT市場！三月底正式推出「NFT商店」',
    title: 'Yahoo奇摩攜手Blocto插旗NFT市場！三月底正式推出「NFT商店」',
  },
];
