import yahoo from '../../../static/images/img-yahoo.png';
import nbaTopShot from '../../../static/images/img-nba-top-shot.png';
import cnnVault from '../../../static/images/img-cnn-vault.png';
import motogpIgnition from '../../../static/images/img-motogp-ignition.png';
import southChinaMorningPost from '../../../static/images/img-south-china-morning-post.png';
import gogoro from '../../../static/images/img-gogoro.png';
import dapperLabs from '../../../static/images/img-dapper-labs.png';
import rarible from '../../../static/images/img-rarible.png';
import chainlink from '../../../static/images/img-chainlink.png';
import gate from '../../../static/images/img-gate.png';
import mexcGlobal from '../../../static/images/img-mexc-global.png';

export const PromotedPartnerLinks = [
  {
    imgSrc: yahoo,
    href: 'https://www.yahoo.com/',
    name: 'Yahoo',
  },
  {
    imgSrc: nbaTopShot,
    href: 'https://nbatopshot.com/',
    name: 'NBA Top Shot',
  },
  {
    imgSrc: cnnVault,
    href: 'https://vault.cnn.com/',
    name: 'Vault by CNN',
  },
];

export const PartnerLinks = [
  {
    imgSrc: motogpIgnition,
    href: 'https://motogp-ignition.com/',
    name: 'MotoGP Ignition',
  },
  {
    imgSrc: southChinaMorningPost,
    href: 'https://artifact.scmp.com/',
    name: 'South China Morning Post',
  },
  {
    imgSrc: gogoro,
    href: 'https://www.gogoro.com/',
    name: 'GOGORO',
  },
  {
    imgSrc: dapperLabs,
    href: 'https://www.dapperlabs.com/',
    name: 'Dapper Labs',
  },
  {
    imgSrc: rarible,
    href: 'https://rarible.com/',
    name: 'Rarible',
  },
  {
    imgSrc: chainlink,
    href: 'https://chain.link/',
    name: 'Chainlink',
  },
  {
    imgSrc: gate,
    href: 'https://www.gate.io/tw/trade/BLT_USDT',
    name: 'Gate.io',
  },
  {
    imgSrc: mexcGlobal,
    href: 'https://www.mexc.com/exchange/BLT_USDT',
    name: 'MEXC Global',
  },
];
