import React, { Fragment, useEffect, useState } from 'react';

import { FormattedMessage } from 'gatsby-plugin-intl';
import { Box, Flex, Heading } from '@chakra-ui/react';

import AnimatedButton from '../components/animatedButton';
import AnimatedLogo from '../components/animatedLogo';
import Bakers from '../components/bakers';
import Ecosystem from '../components/ecosystem';
import FeatureCard from '../components/featureCard';
import Hero from '../components/hero';
import Layout from '../components/layout';
import Media from '../components/media';
import News from '../components/news';
import Partners from '../components/partners';
import SEO from '../components/seo';
import NewSiteHint from '../components/newSiteHint';
import UserFeedback from '../components/userFeedback';
import isBrowser from '../utils/isBrowser';
import useAOS from '../utils/useAOS';

import blocks from '../../static/images/icon-blocks.svg';
import chain from '../../static/images/icon-chain.svg';
import check from '../../static/images/icon-check.svg';

const FeatureCardInfo = [
  {
    icon: check,
    titleTranslationKey: 'blocto.home.userExperience.title',
    contentTranslationKey: 'blocto.home.userExperience.content',
  },
  {
    icon: chain,
    titleTranslationKey: 'blocto.home.multichain.title',
    contentTranslationKey: 'blocto.home.multichain.content',
  },
  {
    icon: blocks,
    titleTranslationKey: 'blocto.home.ecosystem.title',
    contentTranslationKey: 'blocto.home.ecosystem.content',
  },
];

const IndexPage = () => {
  const [isClient, setIsClient] = useState(false);
  const animation = useAOS({
    'data-aos': 'fade-up',
    'data-aos-duration': '600',
    'data-aos-offset': '400',
  });

  useEffect(() => {
    // Workaround for gatsby not hydrating the page correctly
    // Ref: https://github.com/gatsbyjs/gatsby/discussions/17914
    setIsClient(true);
  }, []);

  return (
    <Layout>
      <SEO />

      <Hero />

      <NewSiteHint />

      <Fragment key={`${isClient}-1`}>
        <Box my={{ base: '120px', md: '250px' }} {...animation}>
          <Heading
            as="h1"
            variant="h1"
            textAlign="center"
            marginBottom="0"
            _after={{
              content: '""',
              display: 'block',
              width: '60px',
              height: '10px',
              bg: 'porttoGrayBlue',
              opacity: 0.1,
              borderRadius: '20px',
              margin: '40px auto 0',
            }}
          >
            <FormattedMessage id="blocto.home.ambition" />
          </Heading>
        </Box>
      </Fragment>

      <Flex
        display={{ base: 'block', md: 'flex' }}
        justifyContent="center"
        my={{ base: '120px', md: '250px' }}
      >
        {FeatureCardInfo.map(
          ({ icon, titleTranslationKey, contentTranslationKey }) => (
            <FeatureCard
              key={titleTranslationKey}
              icon={icon}
              titleTranslationKey={titleTranslationKey}
              contentTranslationKey={contentTranslationKey}
            />
          )
        )}
      </Flex>

      <Fragment key={`${isClient}-2`}>
        <Box my={{ base: '120px', md: '250px' }} {...animation}>
          <Heading
            as="h1"
            variant="h1"
            textAlign="center"
            marginBottom={{ base: '40px', md: '80px' }}
          >
            <FormattedMessage id="blocto.home.feedback" />
          </Heading>

          <UserFeedback />
        </Box>
      </Fragment>

      <Fragment key={`${isClient}-3`}>
        <Box my={{ base: '120px', md: '250px' }} {...animation}>
          <Heading
            as="h1"
            variant="h1"
            textAlign="center"
            marginBottom={{ base: '40px', md: '80px' }}
          >
            <FormattedMessage id="blocto.home.partnership" />
          </Heading>

          <Ecosystem />
        </Box>
      </Fragment>

      <Fragment key={`${isClient}-4`}>
        <Box my={{ base: '120px', md: '250px' }} {...animation}>
          <Heading
            as="h1"
            variant="h1"
            textAlign="center"
            marginBottom={{ base: '40px', md: '80px' }}
          >
            <FormattedMessage id="blocto.home.investors" />
          </Heading>

          <Bakers />
        </Box>
      </Fragment>

      <Fragment key={`${isClient}-5`}>
        <Box my={{ base: '120px', md: '250px' }} {...animation}>
          <Heading
            as="h1"
            variant="h1"
            textAlign="center"
            marginBottom={{ base: '40px', md: '80px' }}
          >
            <FormattedMessage id="blocto.home.partners" />
          </Heading>

          <Partners />
        </Box>
      </Fragment>

      <Fragment key={`${isClient}-6`}>
        <Box my={{ base: '120px', md: '250px' }} {...animation}>
          <Heading
            as="h1"
            variant="h1"
            textAlign="center"
            marginBottom={{ base: '40px', md: '80px' }}
          >
            <FormattedMessage id="blocto.home.media" />
          </Heading>

          <Media />
        </Box>
      </Fragment>

      <Fragment key={`${isClient}-7`}>
        <Box
          id="news"
          margin={{ base: '120px 0 83px', md: '250px 0 315px' }}
          sx={{
            scrollMarginTop: {
              base: '70px',
              md:
                isBrowser() && window.sessionStorage?.length
                  ? '-50px'
                  : '100px',
            },
          }}
          {...animation}
        >
          <Heading
            as="h1"
            variant="h1"
            textAlign="center"
            marginBottom={{ base: '40px', md: '80px' }}
          >
            <FormattedMessage id="blocto.home.inTheNews" />
          </Heading>

          <News />
        </Box>
      </Fragment>

      <Box margin={{ base: '83px 0 120px', md: '315px 0' }} textAlign="center">
        <AnimatedLogo />
        <Heading
          as="h1"
          variant="h1"
          margin={{ base: '30px 0 70px', md: '80px 0 70px' }}
          textAlign="center"
        >
          <FormattedMessage id="blocto.home.joinUs" />
        </Heading>

        <AnimatedButton>
          <FormattedMessage id="blocto.common.startNow" />
        </AnimatedButton>
      </Box>
    </Layout>
  );
};

export default IndexPage;
