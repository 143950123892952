import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FormattedMessage } from 'gatsby-plugin-intl';

import ArrowUpIcon from '../arrowUpIcon';
import { InverstorLinks, Inverstors } from './bakers.const';

const DefaultNumberOfItemsShown = 15;
const RowsOfItemsInMobile = 3;
const ImageWidthInMobile = 124;
const ImageMarginInMobile = 8;
const NumberOfItemsPerLineAboveMobile = 5;

const Bakers = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isMaxWidthMd = useBreakpointValue({ base: true, md: false });

  const handleClick = () => {
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  };

  const inverstorLinks =
    isExpanded || isMaxWidthMd
      ? InverstorLinks
      : InverstorLinks.slice(0, DefaultNumberOfItemsShown);

  const NumberOfItemsPerLineInMobile =
    InverstorLinks.length % RowsOfItemsInMobile === 0
      ? InverstorLinks.length / RowsOfItemsInMobile
      : Math.floor(InverstorLinks.length / RowsOfItemsInMobile) + 1;
  return (
    <Flex flexWrap="wrap" justifyContent="center">
      <Flex
        width="100%"
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        {Inverstors.map((investor) => (
          <Box
            key={investor.name}
            display={{ base: 'flex', md: 'block' }}
            width={{ base: '100%', md: '30%' }}
            alignItems={{ base: 'center' }}
            marginBottom={{ base: '60px', md: '0' }}
            textAlign="center"
            _last={{ marginBottom: '0' }}
          >
            <Image
              src={investor.avatar}
              display="inline-block"
              width={{ base: '80px', md: '180px' }}
              alt="Avatar"
            />
            <Box
              marginTop={{ base: '0', md: '30px' }}
              marginLeft={{ base: '12px', md: '0' }}
              textAlign={{ base: 'left', md: 'center' }}
            >
              <Link href={investor.href} isExternal display="block">
                <Heading
                  as="h3"
                  variant="h3"
                  color="porttoBlack"
                  marginBottom={{ base: '8px', md: '20px' }}
                >
                  {investor.name}
                </Heading>
              </Link>
              <Heading
                as="h5"
                variant="h5"
                fontWeight="400"
                color="porttoGray01"
                whiteSpace="pre-line"
              >
                <FormattedMessage id={investor.titleTranslationKey} />
              </Heading>
            </Box>
          </Box>
        ))}
      </Flex>

      <Flex
        display={{ base: 'block', md: 'flex' }}
        width="100%"
        flexWrap="wrap"
        marginTop={{ base: '36px', md: '30px' }}
        overflow="auto"
      >
        <Flex
          flexWrap="wrap"
          width={{
            base:
              ImageWidthInMobile * NumberOfItemsPerLineInMobile +
              ImageMarginInMobile * 2 * (NumberOfItemsPerLineInMobile - 1),
            md: '100%',
          }}
        >
          {inverstorLinks.map((link) => (
            <Link
              key={link.name}
              href={link.href}
              isExternal
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={{
                base: `${ImageWidthInMobile}px`,
                md: `calc((100% - 80px) / ${NumberOfItemsPerLineAboveMobile})`,
              }}
              height={{ base: '90px', md: '160px' }}
              margin={{ base: `${ImageMarginInMobile}px`, md: '10px' }}
              cursor={!link.href ? 'default' : 'pointer'}
              sx={{
                '@media screen and (max-width: 767px)': {
                  [`:nth-of-type(${NumberOfItemsPerLineInMobile}n)`]: {
                    marginRight: '0',
                  },
                  [`:nth-of-type(${NumberOfItemsPerLineInMobile}n+1)`]: {
                    marginLeft: '0',
                  },
                  [`:nth-of-type(-n+${NumberOfItemsPerLineInMobile})`]: {
                    marginTop: '0',
                  },
                  [`:nth-last-of-type(-n+${NumberOfItemsPerLineInMobile})`]: {
                    marginBottom: '0',
                  },
                },
                '@media screen and (min-width: 768px)': {
                  [`:nth-of-type(${NumberOfItemsPerLineAboveMobile}n)`]: {
                    marginRight: '0',
                  },
                  [`:nth-of-type(${NumberOfItemsPerLineAboveMobile}n+1)`]: {
                    marginLeft: '0',
                  },
                  [`:nth-of-type(-n+${NumberOfItemsPerLineAboveMobile})`]: {
                    marginTop: '0',
                  },
                  [`:nth-last-of-type(-n+${NumberOfItemsPerLineAboveMobile})`]:
                    {
                      marginBottom: '0',
                    },
                },
              }}
            >
              <Image src={link.imgSrc} alt={link.name} />
            </Link>
          ))}
        </Flex>
      </Flex>
      <Button
        display={{ base: 'none', md: 'flex' }}
        justifyContent="center"
        alignItems="center"
        width="36px"
        minWidth="0"
        height="36px"
        padding="0"
        marginTop="60px"
        bg="porttoGray03"
        borderRadius="50%"
        onClick={handleClick}
      >
        <ArrowUpIcon
          fill="#4D5472"
          transform={
            isExpanded ? 'translateY(-2px)' : 'rotate(180deg) translateY(-2px)'
          }
          transition=".3s transform"
        />
      </Button>
    </Flex>
  );
};

export default Bakers;
