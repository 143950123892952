import React from 'react';
import { Flex } from '@chakra-ui/react';

import Carousel from '../carousel';
import Partners from './ecosystem.const';

const AveragePartnersLength = Math.floor(Partners.length / 4);

const Ecosystem = () => (
  <Flex
    flexDirection="column"
    _hover={{ '> div div': { animationPlayState: { lg: 'paused' } } }}
  >
    <Carousel partners={Partners.slice(0, AveragePartnersLength)} />
    <Carousel
      partners={Partners.slice(
        AveragePartnersLength,
        AveragePartnersLength * 2
      )}
      direction="right"
    />
    <Carousel
      partners={Partners.slice(
        AveragePartnersLength * 2,
        AveragePartnersLength * 3
      )}
    />
    <Carousel
      partners={Partners.slice(AveragePartnersLength * 3)}
      direction="right"
    />
  </Flex>
);

export default Ecosystem;
