import cointelegraph from '../../../static/images/img-cointelegraph.png';
import dappRadar from '../../../static/images/img-dappradar.png';
import blockBeats from '../../../static/images/img-blockbeats.png';
import asiaBlockchainMedia from '../../../static/images/img-asia-blockchain-media.png';
import kyrosVentures from '../../../static/images/img-kyros-ventures.png';
import bigcoinCapital from '../../../static/images/img-bigcoin-capital.png';
import zombit from '../../../static/images/img-zombit.png';
import cryptoWesearch from '../../../static/images/img-crypto-wesearch.png';
import rhizomeDao from '../../../static/images/img-rhizome-dao.png';
import dappChaser from '../../../static/images/img-dappchaser.png';

export const MediaLinks = [
  {
    imgSrc: cointelegraph,
    href: 'https://cointelegraph.com/',
    name: 'Cointelegraph',
  },
  {
    imgSrc: dappRadar,
    href: 'https://dappradar.com/',
    name: 'DappRadar',
  },
  {
    imgSrc: blockBeats,
    href: 'https://www.theblockbeats.info/',
    name: 'BlockBeats',
  },
  {
    imgSrc: asiaBlockchainMedia,
    href: 'https://www.abmedia.io/',
    name: 'Asia Blockchain Media',
  },
  {
    imgSrc: kyrosVentures,
    href: 'https://kyros.ventures/',
    name: 'Kyros Ventures',
  },
  {
    imgSrc: bigcoinCapital,
    href: 'https://bigcoincapital.org/',
    name: 'Bigcoin Capital',
  },
  {
    imgSrc: zombit,
    href: 'https://zombit.info/',
    name: 'Zombit Blockchain',
  },
  {
    imgSrc: cryptoWesearch,
    href: 'https://cryptowesearch.com/',
    name: 'Crypto Wesearch',
  },
  {
    imgSrc: rhizomeDao,
    href: 'https://twitter.com/rhizomedao',
    name: 'Rhizome DAO',
  },
  {
    imgSrc: dappChaser,
    href: 'https://www.dappchaser.com/',
    name: 'DAppChaser',
  },
];
