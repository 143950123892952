import React from 'react';
import { Flex, Image, Link } from '@chakra-ui/react';

import { MediaLinks } from './media.const';

const RowsOfItemsInMobile = 2;
const ImageWidthInMobile = 124;
const ImageMarginInMobile = 8;
const NumberOfItemsPerLineAboveMobile = 5;

const Media = () => {
  const NumberOfItemsPerLineInMobile =
    MediaLinks.length % RowsOfItemsInMobile === 0
      ? MediaLinks.length / RowsOfItemsInMobile
      : MediaLinks.length / RowsOfItemsInMobile + 1;
  return (
    <Flex
      display={{ base: 'block', md: 'flex' }}
      width="100%"
      flexWrap="wrap"
      marginTop={{ base: '36px', md: '30px' }}
      overflow="auto"
    >
      <Flex
        flexWrap="wrap"
        width={{
          base:
            ImageWidthInMobile * NumberOfItemsPerLineInMobile +
            ImageMarginInMobile * 2 * (NumberOfItemsPerLineInMobile - 1),
          md: '100%',
        }}
      >
        {MediaLinks.map((link) => (
          <Link
            key={link.name}
            href={link.href}
            isExternal
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={{
              base: `${ImageWidthInMobile}px`,
              md: `calc((100% - 80px) / ${NumberOfItemsPerLineAboveMobile})`,
            }}
            height={{ base: '90px', md: '160px' }}
            margin={{ base: `${ImageMarginInMobile}px`, md: '10px' }}
            cursor={!link.href ? 'default' : 'pointer'}
            sx={{
              '@media screen and (max-width: 767px)': {
                [`:nth-of-type(${NumberOfItemsPerLineInMobile}n)`]: {
                  marginRight: '0',
                },
                [`:nth-of-type(${NumberOfItemsPerLineInMobile}n+1)`]: {
                  marginLeft: '0',
                },
                [`:nth-of-type(-n+${NumberOfItemsPerLineInMobile})`]: {
                  marginTop: '0',
                },
                [`:nth-last-of-type(-n+${NumberOfItemsPerLineInMobile})`]: {
                  marginBottom: '0',
                },
              },
              '@media screen and (min-width: 768px)': {
                [`:nth-of-type(${NumberOfItemsPerLineAboveMobile}n)`]: {
                  marginRight: '0',
                },
                [`:nth-of-type(${NumberOfItemsPerLineAboveMobile}n+1)`]: {
                  marginLeft: '0',
                },
                [`:nth-of-type(-n+${NumberOfItemsPerLineAboveMobile})`]: {
                  marginTop: '0',
                },
                [`:nth-last-of-type(-n+${NumberOfItemsPerLineAboveMobile})`]: {
                  marginBottom: '0',
                },
              },
            }}
          >
            <Image src={link.imgSrc} alt={link.name} />
          </Link>
        ))}
      </Flex>
    </Flex>
  );
};

export default Media;
