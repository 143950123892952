/* eslint-disable max-len */
import nbaTopShot from '../../../static/images/partners/nba-top-shot.png';
import axieInfinity from '../../../static/images/partners/axie-infinity.png';
import melosStudio from '../../../static/images/partners/melos-studio.png';
import ovo from '../../../static/images/partners/ovo.png';
import sportsIcon from '../../../static/images/partners/sports-icon.png';
import racingTime from '../../../static/images/partners/racing-time.png';
import motogpIgnition from '../../../static/images/partners/motogp-ignition.png';
import chainmonsters from '../../../static/images/partners/chainmonsters.png';
import xtingles from '../../../static/images/partners/xtingles.png';
import eternal from '../../../static/images/partners/eternal.png';
import fantastecSwap from '../../../static/images/partners/fantastec-swap.png';
import jambb from '../../../static/images/partners/jambb.png';
import klktn from '../../../static/images/partners/klktn.png';
import mynft from '../../../static/images/partners/mynft.png';
import voxelKnight from '../../../static/images/partners/voxel-knight.png';
import thingFund from '../../../static/images/partners/thing-fund.png';
import rarible from '../../../static/images/partners/rarible.png';
import superRare from '../../../static/images/partners/super-rare.png';
import atheletesUnlimited from '../../../static/images/partners/atheletes-unlimited.png';
import autoglyphs from '../../../static/images/partners/autoglyphs.png';
import benMauroEvolution from '../../../static/images/partners/ben-mauro-evolution.png';
import blockparty from '../../../static/images/partners/blockparty.png';
import canesVault from '../../../static/images/partners/canes-vault.png';
import cardma from '../../../static/images/partners/cardma.png';
import cryptoKitties from '../../../static/images/partners/crypto-kitties.png';
import cryptoPunks from '../../../static/images/partners/crypto-punks.png';
import cryptovoxels from '../../../static/images/partners/cryptovoxels.png';
import darkCountry from '../../../static/images/partners/dark-country.png';
import decentraland from '../../../static/images/partners/decentraland.png';
import hashmasks from '../../../static/images/partners/hashmasks.png';
import lava from '../../../static/images/partners/lava.png';
import makersPlace from '../../../static/images/partners/makers-place.png';
import mintStore from '../../../static/images/partners/mint-store.png';
import nyanCat from '../../../static/images/partners/nyan-cat.png';
import picassoPunks from '../../../static/images/partners/picasso-punks.png';
import polychainMonsters from '../../../static/images/partners/polychain-monsters.png';
import polkaPets from '../../../static/images/partners/polka-pets.png';
import racedayNft from '../../../static/images/partners/raceday-nft.png';
import starly from '../../../static/images/partners/starly.png';
import theSandbox from '../../../static/images/partners/the-sandbox.png';
import vaultByCnn from '../../../static/images/partners/vault-by-cnn.png';
import everbloom from '../../../static/images/partners/everbloom.png';
import sorare from '../../../static/images/partners/sorare.png';
import matrixWorld from '../../../static/images/partners/matrix-world.png';
import flowverse from '../../../static/images/partners/flowverse.png';
import viv3 from '../../../static/images/partners/viv3.png';
import opensea from '../../../static/images/partners/opensea.png';
import lootexMarketplace from '../../../static/images/partners/lootex-marketplace.png';
import enemyMetal from '../../../static/images/partners/enemy-metal.png';
import versus from '../../../static/images/partners/versus.png';
import uniswap from '../../../static/images/partners/uniswap.png';
import pancakeSwap from '../../../static/images/partners/pancake-swap.png';
import raydium from '../../../static/images/partners/raydium.png';
import _1inchExchange from '../../../static/images/partners/1inch-exchange.png';
import compound from '../../../static/images/partners/compound.png';
import quickSwap from '../../../static/images/partners/quick-swap.png';
import kyberSwap from '../../../static/images/partners/kyber-swap.png';
import poolTogether from '../../../static/images/partners/pool-together.png';
import tronTrade from '../../../static/images/partners/tron-trade.png';
import easyDai from '../../../static/images/partners/easy-dai.png';
import ticalUniverse from '../../../static/images/partners/tical-universe.png';
import tuneGo from '../../../static/images/partners/tune-go.png';
import rareRooms from '../../../static/images/partners/rare-rooms.png';
import mugenArt from '../../../static/images/partners/mugen-art.png';
import flowns from '../../../static/images/partners/flowns.png';
import flovatar from '../../../static/images/partners/flovatar.png';
import ballerz from '../../../static/images/partners/ballerz.png';
import artBlocksCurated from '../../../static/images/partners/art-blocks-curated.png';
import _1111ByKevinAbosch from '../../../static/images/partners/1111-by-kevin-abosch.png';
import weAreWhatWeEat from '../../../static/images/partners/we-are-what-we-eat.png';

const Partners = [
  { icon: nbaTopShot, name: 'NBA Top Shot', href: 'https://nbatopshot.com/' },
  {
    icon: axieInfinity,
    name: 'Axie Infinity',
    href: 'https://axieinfinity.com/',
  },
  {
    icon: melosStudio,
    name: 'Melos.Studio',
    href: 'https://www.melos.studio/',
  },
  { icon: ovo, name: 'OVO', href: 'https://www.ovo.space/#/' },
  { icon: sportsIcon, name: 'SportsIcon', href: 'https://sportsicon.com/' },
  {
    icon: racingTime,
    name: 'RacingTime',
    href: 'https://www.racing-time.com/#/',
  },
  {
    icon: motogpIgnition,
    name: 'MotoGP Ignition',
    href: 'https://motogp-ignition.com/',
  },
  {
    icon: chainmonsters,
    name: 'Chainmonsters',
    href: 'https://chainmonsters.com/',
  },
  { icon: xtingles, name: 'xtingles', href: 'https://xtingles.com/' },
  { icon: eternal, name: 'Eternal.gg', href: 'https://eternal.gg/' },
  {
    icon: fantastecSwap,
    name: 'Fantastec SWAP',
    href: 'https://www.swap-fantastec.com/',
  },
  { icon: jambb, name: 'Jambb', href: 'https://www.jambb.com/' },
  {
    icon: klktn,
    name: 'KLKTNofficial',
    href: 'https://twitter.com/klktnofficial',
  },
  { icon: mynft, name: 'Mynft', href: 'https://mynft.io/' },
  {
    icon: voxelKnight,
    name: 'Voxel Knight',
    href: 'https://mobile.twitter.com/voxel_knight',
  },
  { icon: thingFund, name: 'THiNG.FUND', href: 'https://thing.fund/' },
  { icon: rarible, name: 'Rarible', href: 'https://rarible.com/' },
  { icon: superRare, name: 'SuperRare', href: 'https://superrare.com/' },
  {
    icon: atheletesUnlimited,
    name: 'Athletes Unlimited',
    href: 'https://auprosports.com/',
  },
  {
    icon: autoglyphs,
    name: 'Autoglyphs Bot',
    href: 'https://twitter.com/autoglyphs',
  },
  {
    icon: benMauroEvolution,
    name: "Ben Mauro's Evolution & Warfare",
    href: 'https://www.evolution-collect.com/',
  },
  { icon: blockparty, name: 'Blockparty', href: 'https://blockparty.co/' },
  {
    icon: canesVault,
    name: 'Canes Vault by Miami Hurricanes',
    href: 'https://www.canesvault.com/',
  },
  { icon: cardma, name: 'Cardma', href: 'https://cardma.portto.io/' },
  {
    icon: cryptoKitties,
    name: 'CryptoKitties',
    href: 'https://www.cryptokitties.co/',
  },
  {
    icon: cryptoPunks,
    name: 'CryptoPunks',
    href: 'https://www.larvalabs.com/cryptopunks',
  },
  {
    icon: cryptovoxels,
    name: 'Cryptovoxels',
    href: 'https://www.cryptovoxels.com/',
  },
  { icon: darkCountry, name: 'Dark Country', href: 'https://darkcountry.io/' },
  {
    icon: decentraland,
    name: 'Decentraland',
    href: 'https://decentraland.org/',
  },
  { icon: hashmasks, name: 'Hashmasks', href: 'https://www.thehashmasks.com/' },
  { icon: lava, name: 'LAVA', href: 'https://www.lava.ai/' },
  { icon: makersPlace, name: 'Makersplace', href: 'https://makersplace.com/' },
  { icon: mintStore, name: 'Mint', href: 'https://www.mint.store/' },
  { icon: nyanCat, name: 'NYAN CAT', href: 'https://www.nyan.cat/' },
  {
    icon: picassoPunks,
    name: 'Picasso Punks',
    href: 'https://picassopunks.art/',
  },
  {
    icon: polychainMonsters,
    name: 'Polychain Monsters',
    href: 'https://polychainmonsters.com/',
  },
  { icon: polkaPets, name: 'PolkaPets', href: 'https://www.polkapet.world/' },
  { icon: racedayNft, name: 'RaceDay NFT', href: 'https://racedaynft.com/' },
  { icon: starly, name: 'Starly.io', href: 'https://starly.io/' },
  {
    icon: theSandbox,
    name: 'The Sandbox',
    href: 'https://www.sandbox.game/en/',
  },
  { icon: vaultByCnn, name: 'Vault by CNN', href: 'https://vault.cnn.com/' },
  { icon: everbloom, name: 'Everbloom', href: 'https://everbloom.app/' },
  { icon: sorare, name: 'Sorare', href: 'https://sorare.com/' },
  { icon: matrixWorld, name: 'Matrix World', href: 'https://matrixworld.org/' },
  { icon: flowverse, name: 'Flowverse', href: 'https://www.flowverse.co/' },
  { icon: viv3, name: 'VIV三', href: 'https://viv3.com/' },
  { icon: opensea, name: 'OpenSea', href: 'https://opensea.io/' },
  {
    icon: lootexMarketplace,
    name: 'Lootex Marketplace',
    href: 'https://lootex.io/',
  },
  { icon: enemyMetal, name: 'Enemy Metal', href: 'https://enemymetal.com/' },
  { icon: versus, name: 'Versus', href: 'https://www.versus.auction/' },
  { icon: uniswap, name: 'Uniswap', href: 'https://uniswap.org/' },
  {
    icon: pancakeSwap,
    name: 'PancakeSwap',
    href: 'https://pancakeswap.finance/',
  },
  { icon: raydium, name: 'Raydium', href: 'https://raydium.io/' },
  {
    icon: _1inchExchange,
    name: '1inch Exchange',
    href: 'https://app.1inch.io/',
  },
  { icon: compound, name: 'Compound', href: 'https://compound.finance/' },
  { icon: quickSwap, name: 'QuickSwap', href: 'https://quickswap.exchange/' },
  { icon: kyberSwap, name: 'Kyber Swap', href: 'https://kyberswap.com/' },
  {
    icon: poolTogether,
    name: 'PoolTogether',
    href: 'https://pooltogether.com/',
  },
  { icon: tronTrade, name: 'Tron Trade', href: 'https://trontrade.io/' },
  { icon: easyDai, name: 'EasyDAI', href: 'https://easydai.app/' },
  {
    icon: ticalUniverse,
    name: 'Tical Universe',
    href: 'https://www.ticalnft.com/',
  },
  { icon: tuneGo, name: 'TuneGO', href: 'https://www.tunego.com/' },
  { icon: rareRooms, name: 'RareRooms', href: 'https://www.rarerooms.io/' },
  { icon: mugenArt, name: 'Mugen ARt', href: 'https://www.mugenart.io/' },
  { icon: flowns, name: 'Flowns', href: 'https://www.flowns.org/' },
  { icon: flovatar, name: 'Flovatar', href: 'https://flovatar.com/' },
  { icon: ballerz, name: 'BALLERZ', href: 'https://ongaia.com/ballerz' },
  {
    icon: artBlocksCurated,
    name: 'Art Blocks Curated',
    href: 'https://www.artblocks.io/',
  },
  {
    icon: _1111ByKevinAbosch,
    name: '1111 by KEVIN ABOSCH',
    href: 'https://kevinabosch.com/1111/',
  },
  {
    icon: weAreWhatWeEat,
    name: 'We Are What We Eat',
    href: 'https://curation.echox.app/nft/curation/x7QwBXOoxEkvV8z?_gl=1*6uut5d*_ga*MzYzOTc5MjYuMTY0MTE4NzI2Mw..*_ga_8GBV5KM0TV*MTY0MTE4NzI2Mi4xLjEuMTY0MTE4ODE5OS4w',
  },
];

export default Partners;
