import kevinChou from '../../../static/images/img-baker-kevin-chou.png';
import markCuban from '../../../static/images/img-baker-mark-cuban.png';
import rohamGharegozlou from '../../../static/images/img-baker-roham-gharegozlou.png';

import _500Global from '../../../static/images/img-500-global.png';
import animocaBrands from '../../../static/images/img-animoca-brands.png';
import appworks from '../../../static/images/img-appworks.png';
import axia8Ventures from '../../../static/images/img-axia8-ventures.png';
import btc12 from '../../../static/images/img-btc12.png';
import cmsHoldings from '../../../static/images/img-cms-holdings.png';
import continueCapital from '../../../static/images/img-continue-capital.png';
import dealeanCapital from '../../../static/images/img-dealean-capital.png';
import decasonic from '../../../static/images/img-decasonic.png';
import digitalStrategies from '../../../static/images/img-digital-strategies.png';
import divergenceVentures from '../../../static/images/img-divergence-ventures.png';
import doraHacks from '../../../static/images/img-dora-hacks.png';
import doublePeakGroup from '../../../static/images/img-double-peak-group.png';
import dropVentures from '../../../static/images/img-drop-ventures.png';
import everestVenturesGroup from '../../../static/images/img-everest-ventures-group.png';
import evernewCapital from '../../../static/images/img-evernew-capital.png';
import juneFund from '../../../static/images/img-june-fund.png';
import krypitalGroup from '../../../static/images/img-krypital-group.png';
import ldCapital from '../../../static/images/img-ld-capital.png';
import maskNetwork from '../../../static/images/img-mask-network.png';
import menthaPartners from '../../../static/images/img-mentha-partners.png';
import originCapital from '../../../static/images/img-origin-capital.png';
import palmeraCrypto from '../../../static/images/img-palmera-crypto.png';
import pelith from '../../../static/images/img-pelith.png';
import quantstamp from '../../../static/images/img-quantstamp.png';
import sevenxVentures from '../../../static/images/img-sevenx-ventures.png';
import snzHolding from '../../../static/images/img-snz-holding.png';
import treeCapital from '../../../static/images/img-olive-tree-capital.png';
import versus from '../../../static/images/img-versus.png';
import warburgSerres from '../../../static/images/img-warburg-serres.png';
import waterdripCapital from '../../../static/images/img-waterdrip-capital.png';
import yuanyuzhouVentures from '../../../static/images/img-yuanyuzhou-ventures.png';
import zolaGlobal from '../../../static/images/img-zola-global.png';

export const Inverstors = [
  {
    avatar: markCuban,
    name: 'Mark Cuban',
    titleTranslationKey: 'blocto.home.investors.markCuban.title',
    href: 'https://markcubancompanies.com/blockchain/',
  },
  {
    avatar: kevinChou,
    name: 'Kevin Chou',
    titleTranslationKey: 'blocto.home.investors.kevinChou.title',
    href: 'https://geng.gg/',
  },
  {
    avatar: rohamGharegozlou,
    name: 'Roham Gharegozlou',
    titleTranslationKey: 'blocto.home.investors.rohamGharegozlou.title',
    href: 'https://www.dapperlabs.com/',
  },
];

export const InverstorLinks = [
  {
    imgSrc: animocaBrands,
    href: 'https://www.animocabrands.com/',
    name: 'Animoca Brands',
  },
  {
    imgSrc: _500Global,
    href: 'https://500.co/',
    name: '500 Global',
  },
  {
    imgSrc: appworks,
    href: 'https://appworks.tw/',
    name: 'AppWorks',
  },
  {
    imgSrc: quantstamp,
    href: 'https://quantstamp.com/',
    name: 'Quantstamp',
  },
  {
    imgSrc: warburgSerres,
    href: 'https://www.warburgserres.com/',
    name: 'Warburg Serres',
  },
  {
    imgSrc: juneFund,
    href: 'https://june.fund/',
    name: 'June Fund',
  },
  {
    imgSrc: cmsHoldings,
    href: 'http://cmsholdings.io/',
    name: 'CMS Holdings',
  },
  {
    imgSrc: everestVenturesGroup,
    href: 'https://www.evg.co/',
    name: 'Everest Ventures Group',
  },
  {
    imgSrc: snzHolding,
    href: 'https://snzholding.com/',
    name: 'SNZ Holding',
  },
  {
    imgSrc: btc12,
    href: 'https://www.crunchbase.com/organization/btc12-capital',
    name: 'BTC12 Capital',
  },
  {
    imgSrc: treeCapital,
    href: 'https://www.olivetreecapital.com/',
    name: 'Olive Tree Capital',
  },
  {
    imgSrc: continueCapital,
    href: 'https://continue.capital/',
    name: 'Continue Capital',
  },
  {
    imgSrc: sevenxVentures,
    href: 'http://www.7xvc.com/',
    name: 'SevenX',
  },
  {
    imgSrc: maskNetwork,
    href: 'https://mask.io/',
    name: 'Maskbook Network',
  },
  {
    imgSrc: dropVentures,
    href: 'https://drops.ventures/',
    name: 'Drop Ventures',
  },
  {
    imgSrc: divergenceVentures,
    href: 'https://www.div.vc/',
    name: 'Divergence Ventures',
  },
  {
    imgSrc: ldCapital,
    href: 'https://ldcap.com/',
    name: 'LD Capital',
  },
  {
    imgSrc: waterdripCapital,
    href: 'http://waterdrip.io/',
    name: 'Waterdrip Capital',
  },
  {
    imgSrc: doublePeakGroup,
    href: 'https://doublepeak.io/',
    name: 'Double Peak Group',
  },
  {
    imgSrc: versus,
    href: 'https://www.versus.auction/',
    name: 'Versus',
  },
  {
    imgSrc: zolaGlobal,
    href: 'https://www.zolaglobal.com/',
    name: 'Zola Global',
  },
  {
    imgSrc: decasonic,
    href: 'https://www.decasonic.com/',
    name: 'Decasonic',
  },
  {
    imgSrc: evernewCapital,
    href: 'https://www.evernewcap.com/',
    name: 'Evernew Capital',
  },
  {
    imgSrc: menthaPartners,
    href: 'https://mentha.partners/',
    name: 'Mentha Partners',
  },
  {
    imgSrc: palmeraCrypto,
    href: 'https://www.palmeracrypto.com/',
    name: 'Palmera Crypto Partners',
  },
  {
    imgSrc: krypitalGroup,
    href: 'https://krypital.com/',
    name: 'Krypital Group',
  },
  {
    imgSrc: pelith,
    href: 'https://pelith.com/',
    name: 'Pelith',
  },
  {
    imgSrc: dealeanCapital,
    href: 'https://twitter.com/DealeanCapital',
    name: 'Dealean Capital',
  },
  {
    imgSrc: yuanyuzhouVentures,
    href: 'https://yuanyuzhou.org/',
    name: 'Yuanyuzhou Ventures',
  },
  {
    imgSrc: digitalStrategies,
    href: 'https://digistrats.com/',
    name: 'Digital Strategies',
  },
  {
    imgSrc: originCapital,
    href: 'https://theorigincapital.com/',
    name: 'Origin Capital',
  },
  {
    imgSrc: axia8Ventures,
    href: 'https://mobile.twitter.com/Axia8Ventures',
    name: 'Axia8 Ventures',
  },
  {
    imgSrc: doraHacks,
    href: 'https://dorahacks.io/',
    name: 'DoraHacks',
  },
];
