import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  useStyleConfig,
} from '@chakra-ui/react';
import { useIntl } from 'gatsby-plugin-intl';

import { NewsInEnglish } from './const/en';
import { NewsInChinese } from './const/zh';

const News = () => {
  const cardStyles = useStyleConfig('Card');
  const { locale } = useIntl();

  const Posts = locale === 'en' ? NewsInEnglish : NewsInChinese;
  return (
    <Flex
      flexWrap="wrap"
      sx={{
        '@media screen and (max-width: 1124px)': {
          flexWrap: 'unset',
          overflow: 'auto',
          padding: '20px', // For not cutting the box shadow
          margin: '-20px', // For not cutting the box shadow
        },
      }}
    >
      {Posts.map((post) => (
        <Link
          key={post.title}
          href={post.url}
          isExternal
          flex={{ base: '1 0 300px', md: '1 0 32%' }}
          minHeight="350px"
          margin={{ base: '10px', md: '1%' }}
          marginTop={{ base: '0' }}
          marginBottom={{ base: '0' }}
          _first={{
            marginLeft: { base: '0' },
          }}
          _last={{
            marginRight: { base: '0' },
          }}
          sx={{
            '@media screen and (min-width: 1124px)': {
              ':nth-of-type(3n)': {
                marginRight: '0',
              },
              ':nth-of-type(3n+1)': {
                marginLeft: '0',
              },

              ':nth-of-type(-n+3)': {
                marginTop: '0',
              },
              ':nth-of-type(n+4)': {
                marginBottom: '0',
              },
            },
            '@media screen and (min-width: 767px) and (max-width: 1123px)': {
              flex: '1 0 45%',
              margin: '0 10px',
            },
          }}
        >
          <Box
            __css={cardStyles}
            width="100%"
            height="100%"
            borderRadius="20px"
          >
            <Image
              width="100%"
              height={{ base: '168px', md: '214px' }}
              objectFit="cover"
              borderRadius="20px"
              src={post.image}
            />

            <Box padding={{ base: '16px 20px 20px', md: '24px 30px 30px' }}>
              <Heading
                variant="h4"
                fontWeight="500"
                lineHeight="26px"
                color="porttoBlack"
                marginBottom={{ base: '2px', md: '10px' }}
              >
                {post.title}
              </Heading>
            </Box>
          </Box>
        </Link>
      ))}
    </Flex>
  );
};

export default News;
