import React from 'react';
import { Flex, Image, Link } from '@chakra-ui/react';

import { PartnerLinks, PromotedPartnerLinks } from './partners.const';

const RowsOfItemsInMobile = 2;
const ImageWidthInMobile = 124;
const ImageMarginInMobile = 8;
const NumberOfItemsPerLineAboveMobile = 5;

const Partners = () => {
  const NumberOfItemsPerLineInMobile =
    PartnerLinks.length % RowsOfItemsInMobile === 0
      ? PartnerLinks.length / RowsOfItemsInMobile
      : Math.floor(PartnerLinks.length / RowsOfItemsInMobile) + 1;
  return (
    <>
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        {PromotedPartnerLinks.map((link) => (
          <Link
            key={link.name}
            href={link.href}
            isExternal
            flex="1"
            width={{ base: '100%', md: 'calc((30% - 40px) / 3)' }}
            margin={{ base: '8px 0', md: '0 10px' }}
            borderRadius="20px"
            overflow="hidden"
            _first={{ marginLeft: '0', marginTop: '0' }}
            _last={{ marginRight: '0', marginBottom: '0' }}
          >
            <Image
              src={link.imgSrc}
              alt={link.name}
              width="100%"
              height={{ base: '150px', md: 'auto' }}
              objectFit="cover"
            />
          </Link>
        ))}
      </Flex>

      <Flex
        display={{ base: 'block', md: 'flex' }}
        width="100%"
        flexWrap="wrap"
        marginTop={{ base: '37px', md: '40px' }}
        overflow="auto"
      >
        <Flex
          flexWrap="wrap"
          width={{
            base:
              ImageWidthInMobile * NumberOfItemsPerLineInMobile +
              ImageMarginInMobile * 2 * (NumberOfItemsPerLineInMobile - 1),
            md: '100%',
          }}
        >
          {PartnerLinks.map((link) => (
            <Link
              key={link.name}
              href={link.href}
              isExternal
              width={{
                base: `${ImageWidthInMobile}px`,
                md: `calc((100% - 80px) / ${NumberOfItemsPerLineAboveMobile})`,
              }}
              margin={{ base: `${ImageMarginInMobile}px`, md: '10px' }}
              cursor={!link.href ? 'default' : 'pointer'}
              sx={{
                '@media screen and (max-width: 767px)': {
                  [`:nth-of-type(${NumberOfItemsPerLineInMobile}n)`]: {
                    marginRight: '0',
                  },
                  [`:nth-of-type(${NumberOfItemsPerLineInMobile}n+1)`]: {
                    marginLeft: '0',
                  },
                  [`:nth-of-type(-n+${NumberOfItemsPerLineInMobile})`]: {
                    marginTop: '0',
                  },
                  [`:nth-last-of-type(-n+${NumberOfItemsPerLineInMobile})`]: {
                    marginBottom: '0',
                  },
                },
                '@media screen and (min-width: 768px)': {
                  [`:nth-of-type(${NumberOfItemsPerLineAboveMobile}n)`]: {
                    marginRight: '0',
                  },
                  [`:nth-of-type(${NumberOfItemsPerLineAboveMobile}n+1)`]: {
                    marginLeft: '0',
                  },
                  [`:nth-of-type(-n+${NumberOfItemsPerLineAboveMobile})`]: {
                    marginTop: '0',
                  },
                  [`:nth-last-of-type(-n+${NumberOfItemsPerLineAboveMobile})`]:
                    {
                      marginBottom: '0',
                    },
                },
              }}
            >
              <Image src={link.imgSrc} alt={link.name} />
            </Link>
          ))}
        </Flex>
      </Flex>
    </>
  );
};

export default Partners;
